import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, typeName = '', divisionInfo, totalLength, totalAmount) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('org_pro_division.division'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.region'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.disTrict'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.upz'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.warehouseName'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.no_of_warehouse'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.capacity'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.warehouse_status'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.comments'), style: 'th', alignment: 'center' }
          ]
        ]
        // const newData = []
        data.forEach((division, divisionkey) => {
          division.regions.forEach((region, index) => {
            region.districts.forEach((district, index2) => {
              district.upazilas.forEach((upazilas, index3) => {
                upazilas.list.forEach((list, index4) => {
                  const NewLoop = [{ text: vm.$n(list.slNew), style: 'td', alignment: 'center' }]
                  if (index3 === 0 && index === 0 && index2 === 0 && index4 === 0) {
                    NewLoop.push({ text: (i18n.locale === 'bn') ? division.division_name_bn : division.division_name, style: 'td', rowSpan: division.rowspan + division.regions.length, alignment: 'center' })
                  } else {
                    NewLoop.push({})
                  }
                  if (index2 === 0 && index3 === 0 && index4 === 0) {
                    NewLoop.push({ text: (i18n.locale === 'bn') ? region.region_name_bn : region.region_name, style: 'td', rowSpan: region.rowspan, alignment: 'center' })
                  } else {
                    NewLoop.push({})
                  }
                  if (index3 === 0 && index4 === 0) {
                    NewLoop.push({ text: (i18n.locale === 'bn') ? district.district_name_bn : district.district_name, style: 'td', rowSpan: district.rowspan, alignment: 'center' })
                  } else {
                    NewLoop.push({})
                  }
                  if (index4 === 0) {
                    NewLoop.push({ text: (i18n.locale === 'bn') ? upazilas.upazila_name_bn : upazilas.upazila_name, style: 'td', rowSpan: upazilas.rowspan, alignment: 'center' })
                  } else {
                    NewLoop.push({})
                  }
                  NewLoop.push(
                    { text: (i18n.locale === 'bn') ? list.name_bn : list.name, style: 'td', alignment: 'center' },
                    { text: vm.$n(1), style: 'td', alignment: 'right' },
                    { text: vm.$n(list.capacity), style: 'td', alignment: 'right' },
                    { text: (i18n.locale === 'bn') ? list.warehouse_user.users_name_bn : list.warehouse_user.users_name, style: 'td', alignment: 'center' },
                    { text: (i18n.locale === 'bn') ? list.comments_bn : list.comments, style: 'td', alignment: 'center' }
                  )
                  allRows.push(NewLoop)
                })
              })
            })
            const RegList = [
              {},
              {},
              { text: (i18n.locale === 'bn') ? region.region_name_bn + ' ' + vm.$t('fertilizerReport.total') : region.region_name + ' ' + vm.$t('fertilizerReport.total'), style: 'th', colSpan: 4, alignment: 'right' },
              {},
              {},
              {},
              { text: vm.$n(region.rowspan), style: 'th', alignment: 'right' },
              { text: vm.$n(region.total), style: 'th', alignment: 'right' },
              {},
              {}
            ]
            allRows.push(RegList)
          })
          const RegList = [
            { text: (i18n.locale === 'bn') ? division.division_name_bn + ' ' + vm.$t('fertilizerReport.total') : division.division_name + ' ' + vm.$t('fertilizerReport.total'), style: 'th', colSpan: 6, alignment: 'right' },
            {},
            {},
            {},
            {},
            {},
            { text: vm.$n(division.rowspan), style: 'th', alignment: 'right' },
            { text: vm.$n(division.total), style: 'th', alignment: 'right' },
            {},
            {}
          ]
          allRows.push(RegList)
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '10%', '10%', '12%', '13%', '10%', '10%', '10%', '10%', '10%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            header3: {
              fontSize: 9,
              margin: [0, 0, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            krishi: {
              margin: [0, -5, 0, 15],
              alignment: 'center'
            },
            report_type: {
              fontSize: 9,
              margin: [0, 2, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('warehouse-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
