<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.warehouse_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
          <b-row>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Division" vid="division_id">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            label-for="division_id"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
              {{$t('warehouse_report.division')}}
            </template>
              <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{ $t('globalTrans.select') }} </b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="District" vid="district_id">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            :label="$t('warehouse_report.district')"
            label-for="district_id"
            slot-scope="{ valid, errors }"
            >
              <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Upazila" vid="upazilla_id">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('warehouse_report.upazilla')"
              label-for="upazilla_id"
              slot-scope="{ valid, errors }"
              >
              <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse Type" vid="warehouse_id">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_report.warehouse_name')"
                slot-scope="{ valid, errors }"
                >
                <b-form-select
                plain
                v-model="search.warehouse_id"
                :options="godownInfoList"
                id="warehouse_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse User" vid="warehouse_user_id">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_user_id"
                :label="$t('fertilizerConfig.warehouse_user.users_name')"
                slot-scope="{ valid, errors }"
                >
                <b-form-select
                plain
                v-model="search.warehouse_user_id"
                :options="WarehouseUserList"
                id="warehouse_user_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.warehouse_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay>
                <div style="border: 2px solid;margin:10px;">
                  <b-row>
                    <b-col>
                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                        {{ $t('fertilizerReport.warehouse_report') }}
                      </list-report-head>
                    </b-col>
                  </b-row>
                  <b-col md="12" class="table-responsive">
                    <b-overlay>
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th class="text-center">{{ $t('org_pro_division.division') }}</b-th>
                            <b-th class="text-center">{{ $t('fertilizerReport.region') }}</b-th>
                            <b-th class="text-center">{{ $t('fertilizerReport.disTrict') }}</b-th>
                            <b-th class="text-center">{{ $t('fertilizerReport.upz') }}</b-th>
                            <b-th class="text-center">{{ $t('movement.warehouseName') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.no_of_warehouse') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.capacity') }}</b-th>
                            <b-th class="text-center">{{ $t('fertilizerReport.warehouse_status') }}</b-th>
                            <b-th class="text-center">{{ $t('fertilizerReport.comments') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <tbody>
                          <template v-for="(division, divisionkey) in report">
                            <slot v-for="(region, index) in division.regions">
                              <slot v-for="(district, index2) in region.districts">
                                <slot v-for="(upazilas, index3) in district.upazilas">
                                  <tr v-for="(list, index4) in upazilas.list" :key="list.slNew">
                                    <td class="text-center">{{$n(list.slNew)}}</td>
                                    <slot v-if="index3 == 0 && index == 0 && index2 == 0 && index4 == 0">
                                      <td class="text-center align-middle" :rowspan="division.rowspan + division.regions.length" :key="divisionkey">
                                        {{ ($i18n.locale === 'bn') ? division.division_name_bn : division.division_name }}
                                      </td>
                                    </slot>
                                    <slot v-if="index2 == 0 && index3 == 0 && index4 == 0">
                                      <td class="text-center align-middle" :rowspan="region.rowspan">
                                        {{ ($i18n.locale === 'bn') ? region.region_name_bn : region.region_name }}
                                      </td>
                                    </slot>
                                    <slot v-if="index3 == 0 && index4 == 0">
                                      <td class="text-center align-middle" :rowspan="district.rowspan">
                                        {{ ($i18n.locale === 'bn') ? district.district_name_bn : district.district_name }}
                                      </td>
                                    </slot>
                                    <slot v-if="index4 == 0">
                                      <td class="text-center align-middle" :rowspan="upazilas.list.length">
                                        {{ ($i18n.locale === 'bn') ? upazilas.upazila_name_bn : upazilas.upazila_name}}
                                      </td>
                                    </slot>
                                    <td class="text-center align-middle">{{ ($i18n.locale === 'bn') ? list.name_bn : list.name}}</td>
                                    <td class="align-middle" style="text-align:right">{{$n(1)}}</td>
                                    <td class="align-middle" style="text-align:right">{{$n(list.capacity)}}</td>
                                    <td class="text-center align-middle">{{ ($i18n.locale === 'bn') ? list.warehouse_user.users_name_bn : list.warehouse_user.users_name}}</td>
                                    <td class="text-center align-middle">{{ ($i18n.locale === 'bn') ? list.comments_bn : list.comments}}</td>
                                  </tr>
                                </slot>
                              </slot>
                              <tr :key="'R' + division.division_id + region.region_id">
                                <td></td>
                                <th class="text-right" style="text-align:right" colspan="4">{{ ($i18n.locale === 'bn') ? region.region_name_bn : region.region_name }} {{ $t('fertilizerReport.total') }}</th>
                                <th style="text-align:right">{{$n(region.rowspan)}}</th>
                                <th style="text-align:right">{{$n(region.total)}}</th>
                                <th class="text-center" colspan="2"></th>
                              </tr>
                            </slot>
                            <tr :key="'D' + division.division_id">
                              <th class="text-right" style="text-align:right" colspan="6">
                                {{ ($i18n.locale === 'bn') ? division.division_name_bn : division.division_name }} {{ $t('fertilizerReport.total') }}
                              </th>
                              <th style="text-align:right">{{$n(division.rowspan)}}</th>
                              <th style="text-align:right">{{$n(division.total)}}</th>
                              <th class="text-center" colspan="2"></th>
                            </tr>
                          </template>
                        </tbody>
                      </b-table-simple>
                    </b-overlay>
                  </b-col>
                </div>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, warehouseReportApi, warehouseUserSelectList } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      search: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        org_id: 3,
        fertilizer_id: 0,
        warehouse_user_id: 0,
        warehouse_id: 0
      },
      warehouseNameList: [],
      report: [],
      farmerReport: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      tempList: [],
      organization: [],
      warehouseUserData: [],
      testId: 0,
      totalAmount: 0,
      totalLength: 0,
      rows: [],
      divisionInfo: {
        name_en: '',
        name_bn: ''
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    WarehouseUserList: function () {
      const listObject = this.warehouseUserData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    regionList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    formTitle () {
       return this.$t('fertilizerReport.warehouse_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    // 'search.division_id': function (newVal, oldVal) {
    //   this.districtList = this.getDistrictList(newVal)
    // },
    // 'search.region_id': function (newVal, oldVal) {
    //   this.districtList = this.getRegionDistrictList(newVal)
    // },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    }
  },
  created () {
    // this.loadData()
    this.warehouseUserDataFn()
  },
  mounted () {
    core.index()
  },
  methods: {
    warehouseUserDataFn () {
        RestApi.getData(seedFertilizerServiceBaseUrl, warehouseUserSelectList, {}).then(response => {
          this.warehouseUserData = response
        })
    },
    getDivisionName () {
      const division = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(this.search.division_id))
      this.divisionInfo.name_en = division !== undefined ? division.text_en : ''
      this.divisionInfo.name_bn = division !== undefined ? division.text_bn : ''
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.showData = true
      this.loadData()
      this.getDivisionName()
    },
    checkUpazial (checkData) {
      let total = 0
      checkData.forEach((element, key) => {
        element.list.forEach((element2, key2) => {
          total++
        })
      })
      return total
    },
    checkUpazialRegion (checkData) {
      let total = 0
      checkData.forEach((element, key) => {
        element.upazilas.forEach((element1, key) => {
          element1.list.forEach((element2, key2) => {
            total++
          })
        })
      })
      return total
    },
    checkUpazialRegionDivisin (regions) {
      let total = 0
      regions.forEach((checkData, key1) => {
        checkData.districts.forEach((element, key) => {
          element.upazilas.forEach((element1, key) => {
            element1.list.forEach((element2, key2) => {
              total++
            })
          })
        })
      })
      return total
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, warehouseReportApi, params).then(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.tableShow = true
            this.totalLength = response.totalLength
            this.totalAmount = response.totalAmount
            // this.report = response.data
            this.report = this.getRelationalData(response.data)
          }
        } else {
          this.tableShow = false
          this.totalLength = 0
          this.totalAmount = 0
          this.report = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const regionLists = this.regionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const allDatas = []
      let sl = 0
      data.forEach((division, divisionkey) => {
        let divTotal = 0
        const divisionList = this.$store.state.commonObj.divisionList.find(regionList => regionList.value === division.division_id)
        division.division_name = divisionList !== undefined ? divisionList.text_en : ''
        division.division_name_bn = divisionList !== undefined ? divisionList.text_bn : ''
        division.rowspan = this.checkUpazialRegionDivisin(division.regions)
        division.regions.forEach((element, key) => {
          let total = 0
          const regionObject = regionLists.find(regionList => regionList.value === element.region_id)
          element.region_name = regionObject !== undefined ? regionObject.text_en : ''
          element.region_name_bn = regionObject !== undefined ? regionObject.text_bn : ''
          element.rowspan = this.checkUpazialRegion(element.districts)
          element.districts.forEach((element2, key2) => {
            const districtObject = districtList.find(district => district.value === element2.district_id)
            element2.district_name = districtObject !== undefined ? districtObject.text_en : ''
            element2.district_name_bn = districtObject !== undefined ? districtObject.text_bn : ''
            element2.rowspan = this.checkUpazial(element2.upazilas)
            element2.upazilas.forEach((element3, key2) => {
              const upazilaObject = upazilaList.find(upazila => upazila.value === element3.upazila_id)
              element3.upazila_name = upazilaObject !== undefined ? upazilaObject.text_en : ''
              element3.upazila_name_bn = upazilaObject !== undefined ? upazilaObject.text_bn : ''
              element3.list.forEach((element4, key4) => {
                total += element4.capacity
                divTotal += element4.capacity
                sl++
                element4.slNew = sl
              })
            })
          })
          element.total = total
        })
        division.total = divTotal
        allDatas[divisionkey] = division
      })
      return allDatas
    },
    pdfExport () {
      const reportTypeTitle = ''
      const reportTitle = this.$t('fertilizerReport.warehouse_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.report, this, reportTypeTitle, this.divisionInfo, this.totalLength, this.totalAmount)
    },
    getPdfData () {
        const delivery = [
            { text: this.$i18n.locale === 'bn' ? this.warehouseName_bn : this.warehouseName },
            { text: this.$n(this.allocationData[0].tot_complain) },
            { text: this.$n(this.allocationData[0].total_resolve) },
            { text: this.$n(this.allocationData[0].ratio) }
          ]
        return delivery
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
